(function () {
  'use strict';

  angular
  .module('neo.home.ajuntaments.ajuntament.informacio')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.ajuntaments.ajuntament.informacio', {
      url: '/informacio',
      views: {
        '': {
          templateUrl: 'home/ajuntaments/ajuntament/informacio/informacio.tpl.html',
          controller: 'InformacioAjuntamentCtrl',
          controllerAs: 'vm'
        },
        'image@home.ajuntaments.ajuntament.informacio': {
          templateUrl: 'home/ajuntaments/ajuntament/informacio/image/image.tpl.html',
          controller: 'ImageCtrl',
          controllerAS: 'vm'
        },
        'people@home.ajuntaments.ajuntament.informacio': {
          templateUrl: 'home/ajuntaments/ajuntament/informacio/people/people.tpl.html',
          controller: 'AjuntamentPeopleCtrl',
          controllerAs: 'vm',
          resolve: {
            users: function ($stateParams, OrganisationUsers, $q) {
              var deferred = $q.defer();
              OrganisationUsers.query({id: $stateParams.ajuntamentId},
                function (data) {
                  // returned 200
                  deferred.resolve(data);
                },
                function (response) {
                  deferred.reject(response);
                }
              );

              return deferred.promise;
              //return [{email: "prova@email.com", id: 1}];
            }
          }
        },
        'text@home.ajuntaments.ajuntament.informacio': {
          templateUrl: 'home/ajuntaments/ajuntament/informacio/text.tpl.html'
        },
        'actions@home.ajuntaments.ajuntament.informacio': {
          templateUrl: 'components/actionButton/action-button.tpl.html',
          controller: 'ActionButtonCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
}());
